<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin hóa đơn'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <b-container>
                <b-row class="col-md-12 mb-1 m-0 p-0">
                  <div class="alert-header" style="width: 100%">
                    Chuyển đơn hàng
                    <span class="text-primary">{{ orderId }}</span>
                    sang hóa đơn
                    <span v-show="billType * 1 === 1">bán lẻ</span>
                    <span v-show="billType * 1 === 2">bán buôn</span>
                    .
                    <ul
                      style="
                        padding-left: 15px;
                        line-height: 20px;
                        margin-top: 5px;
                        list-style: circle;
                      "
                    >
                      <li v-show="moneyPaid > 0">
                        Số tiền đã đặt cọc:
                        <span class="text-primary">
                          {{ formatMoney(moneyPaid) }}
                        </span>
                      </li>
                      <li v-show="moneyTransfered > 0">
                        Số tiền đã chuyển khoản:
                        <span class="text-primary">
                          {{ formatMoney(moneyTransfered) }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </b-row>
                <b-row class="col-md-12 mb-3 m-0 p-0">
                  <b-col md="8" class="row mb-2">
                    <b-col class="col-md-3 p-0">
                      <b-form-select
                        id="select-product"
                        v-model="selectTypeSearch"
                        :options="listTypeSearch"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                        @change="changePlaceholder"
                      ></b-form-select>
                    </b-col>
                    <b-col class="col-md-9 p-0">
                      <vue-autosuggest
                        v-model="searchProduct"
                        :suggestions="filteredOptionsProduct"
                        @selected="onSelectedProduct"
                        :limit="10"
                        @input="onInputChangProduct"
                        :input-props="inputSearchProductProp"
                        :should-render-suggestions="
                          (size, loading) =>
                            size >= 0 && !loading && searchProduct !== ''
                        "
                      >
                        <div
                          v-if="selectTypeSearch === 1"
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          <div>
                            <span class="text-primary">
                              ({{ formatMoney(suggestion.item.sellingPrice) }})
                              (
                            </span>
                            <span
                              v-if="suggestion.item.quantityInStock > 0"
                              class="text-success"
                            >
                              Tồn TCB:
                              {{ suggestion.item.quantityInStock }}
                            </span>
                            <span v-else class="text-danger">
                              Tồn TCB:
                              {{ suggestion.item.quantityInStock }}
                            </span>
                            )
                            {{ suggestion.item.productName }}
                          </div>
                        </div>
                        <div
                          v-else-if="selectTypeSearch === 2"
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          <div>
                            <span class="text-primary">
                              ({{ formatMoney(suggestion.item.sellingPrice) }})
                            </span>
                            {{ suggestion.item.productName }}-
                            <span class="text-warning"
                              >IMEI: {{ suggestion.item.imeiCode }}</span
                            >
                          </div>
                        </div>
                      </vue-autosuggest>
                    </b-col>
                  </b-col>
                  <b-col md="4" class="mb-2">
                    <b-form-group>
                      <vue-autosuggest
                        class="border-radius-none"
                        aria-describedby="input-store-live-feedback"
                        :suggestions="filteredOptions"
                        @selected="onSelectedStore"
                        :limit="10"
                        @input="onInputChange"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Tên cửa hàng',
                          style: 'border-radius:0px!important'
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="$v.searchStore.$model"
                        :state="validateState('searchStore')"
                      >
                        <template slot-scope="{ suggestion }">
                          {{ suggestion.item.name }}
                        </template>
                      </vue-autosuggest>
                      <b-form-invalid-feedback
                        :state="validateState('searchStore')"
                        id="input-store-live-feedback"
                        >Vui lòng nhập cửa hàng</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
              <b-container class="bv-example-row">
                <b-row>
                  <b-col md="9"></b-col>
                  <b-col md="3"></b-col>
                </b-row>
                <b-row>
                  <b-col md="9">
                    <b-row>
                      <b-col>
                        <table
                          class="table table-bordered table-vertical-center table-hover"
                        >
                          <thead>
                            <tr>
                              <th class="headerTable" style="textalign: center">
                                STT
                              </th>
                              <th class="name headerTable">Sản phẩm</th>
                              <th class="value headerTable">Số lượng</th>
                              <th class="code headerTable">Giá</th>
                              <th class="code headerTable">Thành tiền</th>
                              <th class="code headerTable">Chiết khấu</th>
                              <th v-if="true"></th>
                            </tr>
                          </thead>

                          <tbody
                            v-for="(item, index) in listBillItem"
                            :key="index"
                          >
                            <BillItem
                              v-bind:productItem="item"
                              v-bind:index="index"
                              v-bind:editable="true"
                              v-on:deleteItem="deleteItemInArray"
                              v-on:update="updateItem"
                              v-on:checkValidImei="checkValidImei"
                              v-on:addProductBonus="showModalAddProductBonus"
                              v-on:changeProductBonus="
                                showModalChangeProductBonus
                              "
                              v-on:addProductPromotion="
                                showModalAddProductPromotion
                              "
                              v-on:addNote="showModalAddNote"
                              v-on:linkToListImei="linkToListImei"
                              v-on:viewInventory="viewInventory"
                            />
                          </tbody>
                          <tfoot>
                            <tr>
                              <th
                                style="text-align: center"
                                id="total"
                                colspan="4"
                              >
                                Tổng phải trả:
                              </th>
                              <td style="text-align: right; padding: 0px 20px">
                                {{ formatMoney(totalPrice) }}
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </table>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group id="input-group-1">
                          <label for="input-name">Họ tên:</label>
                          <b-form-input
                            id="input-2"
                            class="input-style"
                            size="sm"
                            type="text"
                            v-model="customerName"
                            required
                            placeholder="Họ tên khách hàng"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group id="input-group-1">
                          <label for="input-name">Nhân viên bán hàng:</label>
                          <vue-autosuggest
                            aria-describedby="input-phone-live-feedback"
                            :suggestions="filteredOptionsEmployee"
                            @selected="onSelectedSale"
                            :limit="10"
                            @input="onInputChangeSale"
                            :input-props="{
                              id: 'autosuggest__input',
                              placeholder: 'Nhân viên bán hàng'
                            }"
                            :should-render-suggestions="
                              (size, loading) => size >= 0 && !loading
                            "
                            v-model="saleName"
                          ></vue-autosuggest>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group id="input-group-1">
                          <label for="input-name">Điện thoại:</label>
                          <vue-autosuggest
                            v-model="customerPhone"
                            aria-describedby="input-phone-live-feedback"
                            :suggestions="filteredOptionsCustomer"
                            @selected="onSelectedCustomer"
                            :limit="10"
                            @input="onInputChangCustomer"
                            :input-props="{
                              id: 'autosuggest__input',
                              placeholder: 'SĐT Khách hàng'
                            }"
                            :should-render-suggestions="
                              (size, loading) => size >= 0 && !loading
                            "
                          ></vue-autosuggest>
                          <b-form-invalid-feedback
                            :state="validateCustomer()"
                            id="input-phone-live-feedback"
                            >Vui lòng nhập số điện
                            thoại</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group id="input-group-1">
                          <label for="input-name">Nhân viên kỹ thuật:</label>
                          <vue-autosuggest
                            aria-describedby="input-phone-live-feedback"
                            :suggestions="filteredOptionsEmployee"
                            @selected="onSelectedTechnical"
                            :limit="10"
                            @input="onInputChangeTechnical"
                            :input-props="{
                              id: 'autosuggest__input',
                              placeholder: 'Nhân viên kỹ thuật'
                            }"
                            :should-render-suggestions="
                              (size, loading) => size >= 0 && !loading
                            "
                            v-model="technicalName"
                          ></vue-autosuggest>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group id="input-group-1">
                          <label for="input-name">Thành phố:</label>
                          <b-form-select
                            id="input-1"
                            class="select-style"
                            v-model="selectCity"
                            :options="listCity"
                            size="sm"
                            value-field="id"
                            text-field="name"
                            disabled-field="notEnabled"
                            v-on:change="getDistrict"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null" disabled
                                >Chọn thành phố</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group id="input-group-1">
                          <label for="input-name">Nhân viên thu ngân:</label>
                          <vue-autosuggest
                            aria-describedby="input-phone-live-feedback"
                            :suggestions="filteredOptionsEmployee"
                            @selected="onSelectedCash"
                            :limit="10"
                            @input="onInputChangeCash"
                            :input-props="{
                              id: 'autosuggest__input',
                              placeholder: 'Nhân viên thu ngân'
                            }"
                            :should-render-suggestions="
                              (size, loading) => size >= 0 && !loading
                            "
                            v-model="cashierName"
                          ></vue-autosuggest>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group id="input-group-1">
                          <label for="input-name">Quận huyện:</label>
                          <b-form-select
                            id="input-1"
                            class="select-style"
                            v-model="selectDistrict"
                            :options="listDistrict"
                            size="sm"
                            value-field="id"
                            text-field="name"
                            disabled-field="notEnabled"
                            v-on:change="getWard"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null" disabled
                                >Chọn quận</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group id="input-group-1">
                          <label for="input-name">Phường xã:</label>
                          <b-form-select
                            id="input-1"
                            class="select-style"
                            v-model="selectWard"
                            :options="listWard"
                            size="sm"
                            value-field="id"
                            text-field="name"
                            disabled-field="notEnabled"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null" disabled
                                >Chọn phường</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group id="input-group-1">
                          <label for="input-name">Địa chỉ:</label>
                          <b-form-input
                            id="input-2"
                            class="input-style"
                            size="sm"
                            type="text"
                            v-model="address"
                            required
                            placeholder="Địa chỉ khách hàng"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group id="input-group-name">
                          <label for="input-name">Nguồn khách hàng:</label>
                          <b-form-select
                            id="input-1"
                            v-model="selectedCustomerSource"
                            :options="listCustomerSource"
                            size="sm"
                            value-field="id"
                            text-field="name"
                            class="select-style"
                            disabled-field="notEnabled"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null" disabled
                                >Chọn nguồn khách hàng:</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4">
                        <b-form-group id="input-group-1">
                          <label for="input-name">Ghi chú của khách:</label>
                          <b-form-textarea
                            size="sm"
                            id="textarea1"
                            class="input-style"
                            v-model="noteCustomer"
                            :placeholder="'Thêm ghi chú'"
                            :rows="6"
                            :max-rows="6"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group id="input-group-1">
                          <label for="input-name">Ghi chú CSKH:</label>
                          <b-form-textarea
                            size="sm"
                            id="textarea1"
                            class="input-style"
                            v-model="saleNote"
                            :placeholder="'Thêm ghi chú'"
                            :rows="6"
                            :max-rows="6"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="3">
                    <b-form-group id="input-group-1">
                      <label for="input-name">Chiết khấu:</label>
                      <b-form-input
                        id="input-2"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="discount"
                        required
                        v-mask="mask"
                        placeholder="Chiết khấu"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-name">
                      <label for="input-name">Kiểu chiết khấu:</label>
                      <b-form-select
                        id="input-1"
                        class="select-style"
                        v-model="selectDiscountType"
                        :options="listDiscountType"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn kiểu chiết khấu</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <p class="textPayment">Thanh toán</p>
                    <div class="row">
                      <div class="col-md-6">
                        <p class="textTitlePayment">Khách cần trả:</p>
                      </div>
                      <div class="col-md-6">
                        <p class="text-right text-success">
                          {{ convertPrice(moneyToPay) }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <p class="textTitlePayment">Đã thanh toán:</p>
                      </div>
                      <div class="col-md-6">
                        <p class="text-right">{{ convertPrice(sumOfPaid) }}</p>
                      </div>
                    </div>
                    <b-form-group id="input-group-1">
                      <label for="input-name">Tiền mặt</label>
                      <b-form-input
                        id="input-2"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="cashAmount"
                        required
                        placeholder="Nhập số tiền mặt"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group v-show="checkCashAmount()" id="input-group-1">
                      <label for="input-transfer-account"
                        >Tài khoản tiền mặt:</label
                      >
                      <vue-autosuggest
                        aria-describedby="input-cash-live-feedback"
                        v-model="selectedCashAcc"
                        :suggestions="filteredCashAccOptions"
                        @selected="onCashAccSelected"
                        :limit="10"
                        @input="onInputCashAccChange"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhập tài khoản tiền mặt'
                        }"
                      ></vue-autosuggest>
                      <b-form-invalid-feedback
                        :state="validateCash()"
                        id="input-cash-live-feedback"
                      >
                        Vui lòng chọn tài khoản tiền mặt
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="input-group-1">
                      <label for="input-name">Tiền chuyển khoản:</label>
                      <b-form-input
                        id="input-2"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="transferAmount"
                        required
                        placeholder="Nhập số tiền chuyển khoản"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      v-show="checkTransferAmount()"
                      id="input-group-1"
                    >
                      <label for="input-transfer-account"
                        >Tài khoản chuyển khoản:</label
                      >
                      <vue-autosuggest
                        aria-describedby="input-transfer-live-feedback"
                        v-model="selectedAccountant"
                        :suggestions="filteredAccOptions"
                        @selected="onAccSelected"
                        :limit="10"
                        @input="onInputAccChange"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhập tài khoản chuyển khoản'
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                      ></vue-autosuggest>
                      <b-form-invalid-feedback
                        :state="validateTransfer()"
                        id="input-transfer-live-feedback"
                      >
                        Vui lòng chọn tài khoản chuyển khoản
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="input-group-1">
                      <label for="input-name">Tiền quẹt thẻ:</label>
                      <b-form-input
                        id="input-2"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="creditAmount"
                        required
                        placeholder="Tiền quẹt thẻ"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group v-if="checkCreditPrice()" id="input-group-1">
                      <label for="input-cash-account"
                        >Tài khoản quẹt thẻ:</label
                      >
                      <vue-autosuggest
                        aria-describedby="input-credit-live-feedback"
                        v-model="selectedCreditAcc"
                        :suggestions="filteredCreditAccOptions"
                        @selected="onCreditAccSelected"
                        :limit="10"
                        @input="onInputCreditAccChange"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhập tài khoản quẹt thẻ'
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                      ></vue-autosuggest>
                      <b-form-invalid-feedback
                        :state="validateCredit()"
                        id="input-credit-live-feedback"
                      >
                        Vui lòng chọn tài khoản quẹt thẻ
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="input-group-1" v-if="checkCreditPrice()">
                      <b-form-input
                        id="input-2"
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="creditCode"
                        required
                        placeholder="Mã giao dịch"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-1">
                      <label for="input-name">Trả góp</label>
                      <b-form-input
                        id="input-2"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="installedMoneyAmount"
                        required
                        placeholder="Trả góp"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      v-if="checkInstallmentAmount()"
                      id="input-group-1"
                    >
                      <label for="input-intallment">Dịch vụ trả góp:</label>
                      <vue-autosuggest
                        aria-describedby="input-installment-live-feedback"
                        v-model="selectedInstallment"
                        :suggestions="filteredOptionsInstallment"
                        @selected="onInstallmentSelected"
                        :limit="10"
                        @input="onInputInstallmentChange"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhập dịch vụ trả góp'
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                      ></vue-autosuggest>
                      <b-form-invalid-feedback
                        :state="validateInstallment()"
                        id="input-installment-live-feedback"
                        >Vui lòng chọn dịch vụ trả góp</b-form-invalid-feedback
                      >
                    </b-form-group>
                    <b-form-group
                      id="input-group-1"
                      v-if="checkInstallmentAmount()"
                    >
                      <b-form-input
                        id="input-2"
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="installmentCode"
                        required
                        placeholder="Mã hợp đồng"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="input-group-3"
                      v-if="checkInstallmentAmount()"
                    >
                      <b-form-input
                        id="input-3"
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="customerIndentifyNo"
                        required
                        placeholder="Số CMND"
                      ></b-form-input>
                    </b-form-group>
                    <div class="row">
                      <div class="col-md-4">
                        <p class="textTitleFinal">{{ titleFinal }}:</p>
                      </div>
                      <div class="col-md-8">
                        <p class="text-right textFinal">
                          {{ convertPrice(totalAmount) }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>

            <b-modal
              ref="add-product-bonus-modal"
              hide-footer
              :title="modalTitle"
            >
              <v-form ref="form" lazy-validation>
                <b-form-group
                  id="input-group-3"
                  label="Chọn sản phẩm quà tặng:"
                  label-for="input-3"
                >
                  <vue-autosuggest
                    v-model="selectedProductBonus"
                    :suggestions="filteredOptionsProduct"
                    @selected="onSelectedProductBonus"
                    :limit="10"
                    @input="onInputChangeProductBonus"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Chọn sản phẩm quà tặng'
                    }"
                    :should-render-suggestions="
                      (size, loading) =>
                        size >= 0 && !loading && selectedProductBonus !== ''
                    "
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      <div>
                        <span class="text-primary"
                          >({{ formatMoney(suggestion.item.sellingPrice) }})
                          (</span
                        >
                        <span
                          v-if="suggestion.item.quantityInStock > 0"
                          class="text-success"
                          >Tồn TCB: {{ suggestion.item.quantityInStock }}</span
                        >
                        <span v-else class="text-danger"
                          >Tồn TCB: {{ suggestion.item.quantityInStock }}</span
                        >
                        )
                        {{ suggestion.item.productName }}
                      </div>
                    </div>
                  </vue-autosuggest>
                </b-form-group>
                <b-table
                  :items="listProductBonus"
                  :fields="fieldProductBonus"
                  class="table-bordered table-hover col-md-12"
                >
                  <template v-slot:cell(id)="row">
                    <div>
                      <span
                        v-text="row.item.id"
                        class="d-flex justify-content-center"
                      ></span>
                    </div>
                  </template>
                  <template v-slot:cell(price)="row">
                    <div>
                      <span
                        v-text="convertPrice(row.item.price)"
                        class="text-right"
                      ></span>
                    </div>
                  </template>
                  <template v-slot:cell(returnPrice)="row">
                    <div>
                      <span
                        v-text="convertPrice(row.item.returnPrice)"
                        class="text-right"
                      ></span>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <div class="d-flex justify-content-center">
                      <v-icon
                        small
                        class="text-danger"
                        @click="deleteProductBonus(row.item)"
                        v-b-tooltip
                        title="Xóa"
                        >mdi-delete</v-icon
                      >
                    </div>
                  </template>
                </b-table>
                <b-button
                  style="fontweight: 600; width: 100px"
                  variant="primary"
                  size="sm"
                  @click="addProductBonusToListBillItem"
                  >Áp dụng</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalAddProductBonus"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>

            <b-modal
              ref="change-product-bonus-modal"
              hide-footer
              title="Đổi quà tặng"
            >
              <v-form ref="form" lazy-validation>
                <div
                  v-for="(item, i) in listProductVariant"
                  :key="i"
                  class="mb-4"
                >
                  <input
                    type="radio"
                    v-model="choosedPro"
                    :value="item.productId"
                  />
                  {{ item.productName }} | Giá bán:
                  {{ convertPrice(item.sellingPrice) }}
                </div>
                <b-button
                  style="fontweight: 600; width: 100px"
                  variant="primary"
                  size="sm"
                  @click="onChangeProductBonus"
                  >Áp dụng</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalChangeProductBonus"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>

            <b-modal
              ref="add-product-promotion-modal"
              hide-footer
              :title="modalTitle"
              size="xl"
            >
              <v-form ref="form" lazy-validation>
                <!-- <b-form-group id="input-group-3" label="Chọn sản phẩm bán kèm:" label-for="input-3">
                  <vue-autosuggest
                    v-model="selectedProductPromotion"
                    :suggestions="filteredOptionsProduct"
                    @selected="onSelectedProductPromotion"
                    :limit="10"
                    @input="onInputChangeProductPromotion"
                    :input-props="{
                                            id: 'autosuggest__input',
                                            placeholder: 'Chọn sản phẩm bán kèm'
                                          }"
                    :should-render-suggestions="
                                            (size, loading) =>
                                              size >= 0 &&
                                              !loading &&
                                              selectedProductPromotion !== ''
                                          "
                  >
                    <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                      <div>
                        <span class="text-primary">
                          ({{formatMoney(suggestion.item.sellingPrice)}})
                          (
                        </span>
                        <span
                          v-if="suggestion.item.quantityInStock > 0"
                          class="text-success"
                        >Tồn TCB: {{suggestion.item.quantityInStock}}</span>
                        <span
                          v-else
                          class="text-danger"
                        >Tồn TCB: {{suggestion.item.quantityInStock}}</span>
                        )
                        {{suggestion.item.productName}}
                      </div>
                    </div>
                  </vue-autosuggest>
                </b-form-group>-->
                <b-table
                  :items="listProductPromotion"
                  :fields="fieldProductPromotion"
                  class="table-bordered table-hover col-md-12"
                >
                  <template v-slot:head(selected)>
                    <span>
                      <b-form-checkbox
                        v-model="checkAllPro"
                        size="lg"
                        @change="clickAll"
                      ></b-form-checkbox>
                    </span>
                  </template>
                  <!-- check bõ -->
                  <template v-slot:cell(selected)="row">
                    <div class="d-flex justify-content-center">
                      <b-form-checkbox
                        size="lg"
                        v-model="row.item.checked"
                        @change="clickIndex(row.item.id)"
                      ></b-form-checkbox>
                    </div>
                  </template>
                  <template v-slot:cell(id)="row">
                    <div>
                      <span
                        v-text="row.item.id"
                        class="d-flex justify-content-center"
                      ></span>
                    </div>
                  </template>
                  <template v-slot:cell(price)="row">
                    <div>
                      <span
                        v-text="convertPrice(row.item.price)"
                        class="text-right"
                      ></span>
                    </div>
                  </template>
                  <template v-slot:cell(returnPrice)="row">
                    <div>
                      <span
                        v-text="convertPrice(row.item.returnPrice)"
                        class="text-right"
                      ></span>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <div class="d-flex justify-content-center">
                      <v-icon
                        small
                        class="text-danger"
                        @click="deleteProductPromotion(row.item)"
                        v-b-tooltip
                        title="Xóa"
                        >mdi-delete</v-icon
                      >
                    </div>
                  </template>
                </b-table>
                <b-button
                  style="fontweight: 600; width: 100px"
                  variant="primary"
                  size="sm"
                  @click="addProductPromotionToListBillItem"
                  >Áp dụng</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalAddProductPromotion"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-modal
              ref="add-note-modal"
              hide-footer
              :title="'Thêm ghi chú cho sản phẩm ' + productNote"
            >
              <b-row class="mb-5">
                <b-col>
                  <label class="labelInput" for="inputOriginalPrice"
                    >Ghi chú</label
                  >
                  <b-form-textarea
                    id="textarea"
                    v-model="noteBillItem"
                    placeholder="Nhập ghi chú..."
                    rows="5"
                    max-rows="6"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="updateNote"
                    >Lưu</b-button
                  >
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    @click="hidenModalAddNote"
                    >Hủy</b-button
                  >
                </b-col>
              </b-row>
            </b-modal>
          </template>

          <template v-slot:foot>
            <b-container class="bv-example-row">
              <b-row>
                <b-col>
                  <b-button
                    tabindex="15"
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    type="submit"
                    @click="createBill"
                    >Lưu</b-button
                  >
                  <router-link to="/orders" tag="button">
                    <b-button
                      tabindex="16"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                      >Hủy</b-button
                    >
                  </router-link>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
#select-product {
  border-radius: 0px;
  /* border-bottom-right-radius:0px;
border-top-right-radius:0px; */
  box-shadow: none;
  border: 1px solid #ced4da;
}
.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
.input-style {
  border: 1px solid #ced4da;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #babac5;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #babac5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #babac5;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import BillItem from '../../components/BillItem.vue';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import ApiService from '@/core/services/api.service';
import { currencyMask, unMaskPrice } from './../../../utils/common';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import Swal from 'sweetalert2';
import { removeAccents } from './../../../utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      choosedPro: '',
      currentBonusProductId: null,
      listProductVariant: [],
      modalTitle: '',
      fieldProductBonus: [
        {
          key: 'id',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' }
        },
        {
          key: 'name',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        {
          key: 'quantityInStock',
          label: 'Tồn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        {
          key: 'price',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        {
          key: 'returnPrice',
          label: 'Giá thu lại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        { key: 'actions', label: '' }
      ],
      selectedProductBonus: '',
      listProductBonus: [],
      countProductBonus: 0,
      addProBonusForBillItemId: 0,
      countProduct: 0,
      selectedStore: 0,
      moneyTransfered: 0,
      moneyPaid: 0,
      billType: '',
      orderId: '',
      orderInfo: '',
      isOrdering: false,
      isValidImei: true,
      mask: currencyMask,
      customerShipFee: 0,
      shipFee: 0,
      listStore: [],
      selectedCustomerSource: null,
      listCustomerSource: [],
      listCity: [],
      selectCity: null,
      listDistrict: [],
      selectDistrict: null,
      listWard: [],
      selectWard: null,
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm'
        },
        {
          id: 2,
          name: 'Tìm sản phẩm imei'
        }
      ],
      selectTypeSearch: 1,
      selectStatus: 1,
      listBillItem: [],
      discount: 0,
      customerName: '',
      transferAmount: 0,
      address: '',
      creditAmount: 0,
      creditCode: '',
      installedMoneyAmount: 0,
      cashAmount: 0,
      datePay: '',
      saleNote: '',
      dateCome: '',
      timeCome: '',
      search: '',
      noteCustomer: '',
      filteredOptions: [],
      options: [
        {
          data: []
        }
      ],
      searchStore: '',
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: []
        }
      ],
      searchEmployee: '',
      listCustomer: [],
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: []
        }
      ],
      searchCustomer: '',
      selectDiscountType: 1,
      listDiscountType: [
        {
          id: 1,
          name: 'Tiền mặt'
        },
        {
          id: 2,
          name: '%'
        }
      ],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: []
        }
      ],
      searchProduct: '',
      codeShip: '',
      inputSearchProductProp: {
        id: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true
      },
      selectedCreditAcc: '',
      filteredCashAccOptions: [],
      filteredCreditAccOptions: [],
      filteredAccOptions: [],
      cashAccOptions: [
        {
          data: []
        }
      ],
      listCashAccSearch: [],
      creditAccOptions: [
        {
          data: []
        }
      ],
      listCreditAccSearch: [],
      listAccSearch: [],
      selectedAccountant: '',
      accountantOptions: [
        {
          data: []
        }
      ],
      cashAccount: '',
      transferAccount: '',
      saleName: '',
      technicalName: '',
      cashierName: '',
      listEmployeeSearch: [],
      listInstallment: [],
      filteredOptionsInstallment: [],
      optionsInstallment: [
        {
          data: []
        }
      ],
      selectedInstallment: '',
      installmentCode: '',
      selectedCashAcc: '',
      isSubmit: false,
      customerId: '',
      customerPhone: '',
      storeId: '',
      proSTT: 0,
      fieldProductPromotion: [
        {
          key: 'selected',
          label: '',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' }
        },
        {
          key: 'name',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        {
          key: 'quantityInStock',
          label: 'Tồn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        {
          key: 'price',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        {
          key: 'returnPrice',
          label: 'Giá chiết khấu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        { key: 'code', label: 'Mã BPK' }
      ],
      listProductPromotion: [],
      addProPromotionForBillItemId: null,
      countProductPromotion: 0,
      selectedProductPromotion: '',
      timer: null,
      checkAllPro: false,
      productNote: '',
      noteBillItem: '',
      productIdUpdateNote: null,
      onLoading: false,
      customerIndentifyNo: ''
    };
  },
  validations: {
    searchStore: {
      required
    },
    searchCustomer: {
      required
    }
  },
  components: {
    KTCodePreview,
    BillItem
  },
  computed: {
    moneyToPay() {
      let result = this.totalPrice - (this.moneyPaid + this.moneyTransfered);
      return result < 0 ? 0 : result;
    },
    sumOfPaid() {
      let result = this.moneyPaid + this.moneyTransfered;
      return result;
    },
    selectedClassAccountant() {
      const acc = this.listAccSearch.find(
        p => p.name === this.selectedAccountant
      );
      if (acc) {
        return acc.id;
      } else {
        return null;
      }
    },
    totalAmount() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === 1) {
          total +=
            unMaskPrice(element.price) * element.quantity -
            unMaskPrice(element.discount);
        } else if (element.billItemType === 2 && element.checked === false) {
          total -= unMaskPrice(element.returnPrice) * element.quantity;
        } else if (element.billItemType === 3 && element.checked === true) {
          total += unMaskPrice(element.returnPrice) * element.quantity;
        }
      }
      let totalTransferAmount = 0;
      if (this.transferAmount !== '') {
        totalTransferAmount = unMaskPrice(this.transferAmount);
      }
      let totalCreditAmount = 0;
      if (this.creditAmount !== '') {
        totalCreditAmount = unMaskPrice(this.creditAmount);
      }
      let totalInstalledMoneyAmount = 0;
      if (this.installedMoneyAmount !== '') {
        totalInstalledMoneyAmount = unMaskPrice(this.installedMoneyAmount);
      }
      let totalCashAmount = 0;
      if (this.cashAmount !== '') {
        totalCashAmount = unMaskPrice(this.cashAmount);
      }
      let discountAmount = 0;
      if (this.discount !== '') {
        discountAmount = unMaskPrice(this.discount);
      }
      if (this.selectDiscountType === 1) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      let final =
        total -
        (parseInt(totalTransferAmount) +
          parseInt(totalCreditAmount) +
          parseInt(totalInstalledMoneyAmount) +
          parseInt(totalCashAmount));
      let finalAmount = final;
      if (final < 0) {
        finalAmount = final * -1;
      }
      return finalAmount;
    },
    titleFinal() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === 1) {
          total +=
            unMaskPrice(element.price) * element.quantity -
            unMaskPrice(element.discount);
        } else if (element.billItemType === 2 && element.checked === false) {
          total -= unMaskPrice(element.returnPrice) * element.quantity;
        } else if (element.billItemType === 3 && element.checked === true) {
          total += unMaskPrice(element.returnPrice) * element.quantity;
        }
      }
      let totalTransferAmount = 0;
      if (this.transferAmount !== '') {
        totalTransferAmount = unMaskPrice(this.transferAmount);
      }
      let totalCreditAmount = 0;
      if (this.creditAmount !== '') {
        totalCreditAmount = unMaskPrice(this.creditAmount);
      }
      let totalInstalledMoneyAmount = 0;
      if (this.installedMoneyAmount !== '') {
        totalInstalledMoneyAmount = unMaskPrice(this.installedMoneyAmount);
      }
      let totalCashAmount = 0;
      if (this.cashAmount !== '') {
        totalCashAmount = unMaskPrice(this.cashAmount);
      }
      let discountAmount = 0;
      if (this.discount !== '') {
        discountAmount = unMaskPrice(this.discount);
      }
      if (this.selectDiscountType === 1) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      let final =
        total -
        (parseInt(totalTransferAmount) +
          parseInt(totalCreditAmount) +
          parseInt(totalInstalledMoneyAmount) +
          parseInt(totalCashAmount));
      if (final <= 0) {
        return 'Tiền thừa';
      } else {
        return 'Còn thiếu';
      }
    },
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === 1) {
          total +=
            unMaskPrice(element.price) * element.quantity -
            unMaskPrice(element.discount);
        } else if (element.billItemType === 2 && element.checked === false) {
          total -= unMaskPrice(element.returnPrice) * element.quantity;
        } else if (element.billItemType === 3 && element.checked === true) {
          total += unMaskPrice(element.returnPrice) * element.quantity;
        }
      }
      let discountAmount = 0;
      if (this.discount !== '') {
        discountAmount = unMaskPrice(this.discount);
      }
      if (this.selectDiscountType === 1) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      if (total < 0) {
        total = 0;
      }
      return total;
    },
    selectedClassStore() {
      const store = this.listStore.find(s => s.name === this.searchStore);
      if (store) {
        return store.id;
      } else {
        return null;
      }
    },
    selectedClassEmployee() {
      const employee = this.listEmployee.find(
        s => s.fullName === this.searchEmployee
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedClassCustomer() {
      const customer = this.listCustomer.find(
        s => s.phoneNo === this.searchCustomer
      );
      if (customer) {
        return customer.id;
      } else {
        return this.searchCustomer;
      }
    },
    selectedClassProduct() {
      const product = this.listProduct.find(
        p => p.productName === this.searchProduct
      );
      if (product) {
        return product;
      } else {
        return null;
      }
    },
    selectedClassCreditAcc() {
      const acc = this.listCreditAccSearch.find(
        p => p.name === this.selectedCreditAcc
      );
      if (acc) {
        return acc.id;
      } else {
        return null;
      }
    },
    selectedEmployeeSale() {
      const employee = this.listEmployeeSearch.find(
        e => e.fullName === this.saleName
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedEmployeeTechnical() {
      const employee = this.listEmployeeSearch.find(
        e => e.fullName === this.technicalName
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedEmployeeCashier() {
      const employee = this.listEmployeeSearch.find(
        e => e.fullName === this.cashierName
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedInstallmentAccount() {
      const installment = this.listInstallment.find(
        i => i.name === this.selectedInstallment
      );
      if (installment) {
        return installment.id;
      } else {
        return null;
      }
    },
    selectedCashAccount() {
      const acc = this.listCashAccSearch.find(
        acc => acc.name === this.selectedCashAcc
      );
      if (acc) {
        return acc.id;
      } else {
        return null;
      }
    }
  },
  created() {
    // this.fetchProduct();
    this.fetchCity();
    this.fetchStore();
    this.getListOrderSource();
    this.getListEmployee();
    this.getListCustomer();
    this.fetchAccountant();
    this.fetchCreditAcc();
    this.fetchInstallments();
    this.fetchCashAcc();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn bán lẻ', route: '/bills' },
      { title: 'Danh sách hóa đơn bán lẻ', route: '/bills' },
      { title: 'Thêm mới hóa đơn bán lẻ' }
    ]);

    this.orderId = this.$route.query.id;
    this.billType = this.$route.query.billType;
    this.getOrderById();
  },
  methods: {
    onChangeProductBonus() {
      let choosedProduct = null;
      for (let index = 0; index < this.listProductVariant.length; index++) {
        const element = this.listProductVariant[index];
        if (element.productId === this.choosedPro) {
          choosedProduct = element;
          break;
        }
      }
      for (let index = 0; index < this.listBillItem.length; index++) {
        if (this.listBillItem[index].id === this.currentBonusProductId) {
          this.listBillItem[index].productId = choosedProduct.productId;
          this.listBillItem[index].name = choosedProduct.productName;
          break;
        }
      }
      this.$refs['change-product-bonus-modal'].hide();
    },
    hideModalChangeProductBonus() {
      this.$refs['change-product-bonus-modal'].hide();
    },
    showModalChangeProductBonus(id) {
      this.currentBonusProductId = id;
      let product = null;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.id === id) {
          product = element;
          break;
        }
      }
      this.choosedPro = product.productId;
      this.listProductVariant = product.listProductVariant;
      this.$refs['change-product-bonus-modal'].show();
    },
    onInputChangeProductBonus(text) {
      if (!text) {
        text = '';
      }

      this.selectedProductBonus = text;

      this.filteredOptionsProduct = [
        {
          data: this.optionsProduct[0].data
        }
      ];
      this.fetchProduct();
    },
    onSelectedProductBonus(option) {
      let productBonus = option.item;
      this.selectedProductBonus = '';
      if (productBonus.quantityInStock > 0) {
        this.countProductBonus++;
        let accessoryBonus = {
          id: this.countProductBonus,
          productId: productBonus.productId,
          name: productBonus.productName,
          code: 'Quà tặng kèm',
          price: productBonus.sellingPrice,
          quantity: 1,
          type: productBonus.productType,
          imeiCode: '',
          totalPrice: productBonus.sellingPrice,
          discount: '',
          quantityInStock: productBonus.quantityInStock,
          storeId: this.selectedClassStore,
          billItemType: 2,
          checked: true,
          returnPrice: productBonus.returnSellingPrice
        };
        this.listProductBonus.push(accessoryBonus);
      } else {
        this.makeToastFaile('Số lượng sản phẩm tồn trong kho không đủ!');
      }
    },
    addProductBonusToListBillItem() {
      let lastId = 0;
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === this.addProBonusForBillItemId) {
          lastId = this.listBillItem[i].listAccessoryBonus.length;
        }
      }
      this.listProductBonus.forEach((element, index) => {
        let currentId = lastId + (index + 1);
        let accessoryBonus = {
          id: this.addProBonusForBillItemId + currentId / 100,
          productId: element.productId,
          name: element.name,
          code: 'Quà tặng kèm',
          price: element.price,
          quantity: 1,
          type: element.type,
          imeiCode: '',
          totalPrice: element.price,
          discount: '',
          quantityInStock: element.quantityInStock,
          storeId: this.selectedClassStore,
          billItemType: 2,
          checked: true,
          parentId: this.addProBonusForBillItemId,
          returnPrice: element.returnPrice,
          accessoryGroupId: '',
          accessoryGroupCode: ''
        };
        this.listBillItem.push(accessoryBonus);
      });
      this.listBillItem.sort(this.sortById);
      this.hideModalAddProductBonus();
    },
    sortById(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    },
    hideModalAddProductBonus() {
      this.$refs['add-product-bonus-modal'].hide();
    },
    showModalAddProductBonus(id) {
      this.addProBonusForBillItemId = id;
      this.countProductBonus = 0;
      this.listProductBonus = [];
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === this.addProBonusForBillItemId) {
          this.modalTitle =
            'Thêm quà tặng cho sản phẩm ' + this.listBillItem[i].name;
        }
      }
      this.$refs['add-product-bonus-modal'].show();
    },
    getOrderById() {
      ApiService.setHeader();
      ApiService.query(`orders/${this.orderId}`).then(data => {
        const {
          saleNote,
          customerNote,
          customerId,
          createdBy,
          discountAmount,
          discountType,
          transferAmount,
          depositAmount,
          transferAccountName,
          cashAccountName,
          storeId,
          orderItems,
          orderSourceId
        } = data.data.data;
        const store = this.listStore.find(s => s.id === storeId);
        this.saleNote = saleNote;
        this.noteCustomer = customerNote;
        this.customerId = customerId;
        this.getCustomerInfoById();
        this.saleName = createdBy;
        this.discount = discountAmount;
        this.selectDiscountType = discountType;
        this.transferAmount = transferAmount;
        this.cashAmount = depositAmount;
        this.selectedAccountant = transferAccountName;
        this.selectedCreditAcc = cashAccountName;
        this.searchStore = store.name;
        this.storeId = storeId;
        this.selectedStore = storeId;
        this.selectedCustomerSource = orderSourceId;
        this.moneyTransfered = transferAmount;
        this.moneyPaid = depositAmount;
        this.inputSearchProductProp.disabled = false;
        orderItems.forEach(x => {
          if (x.orderItemType === 1) {
            this.proSTT++;
          }
          // this.countProduct++;
          let item = {
            id: x.id,
            productId: x.productId,
            name: x.productName,
            code: x.productCode,
            type: x.productType,
            price: x.productPrice,
            imeiCode: x.imeiCode,
            quantity: x.quantity,
            totalPrice: x.totalAmount,
            discount: x.orderItemType === 1 ? x.discountAmount : '',
            quantityInStock: x.quantityInStock,
            comboItems: x.listProductInCombo,
            storeId: storeId,
            deleted: x.deleted,
            billItemType: x.orderItemType,
            returnPrice: x.repurchasePrice !== null ? x.repurchasePrice : 0,
            checked: x.isGiftTaken !== null ? x.isGiftTaken : true,
            orderItemType: x.orderItemType,
            listAccessoryBonus: [],
            note: '',
            stt: this.proSTT,
            listAccessoryPromotion: x.listAccessoryPromotion,
            parentId: x.belongOrderDetailId
          };
          this.listBillItem.push(item);
        });
      });
    },
    getOrderByCustomer: async function(customerId) {
      let params = {
        customerId: customerId
      };
      await ApiService.setHeader();
      await ApiService.query('orders/getOrder/getOrderByUser', {
        params
      }).then(response => {
        let { status } = response.data.data;
        if (status === 1 || status === 2 || status === 3) {
          this.showConfirmBox();
        }
      });
    },
    checkValidImei(value) {
      this.isValidImei = value;
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.selectedStore = option.item.id;
      this.listBillItem = [];
      this.fetchProduct();
      this.inputSearchProductProp.disabled = false;
      this.fetchAccountant();
      this.fetchCreditAcc();
      this.fetchInstallments();
      this.fetchCashAcc();
      this.transferAmount = 0;
      this.creditAmount = 0;
      this.installedMoneyAmount = 0;
      this.cashAmount = 0;
      this.discount = 0;
      this.selectedCashAcc = '';
      this.selectedInstallment = '';
      this.selectedCreditAcc = '';
      this.selectedAccountant = '';
      this.installmentCode = '';
      this.creditCode = '';
    },
    onSelectedSale(option) {
      this.saleName = option.item;
    },
    onSelectedTechnical(option) {
      this.technicalName = option.item;
    },
    onSelectedCash(option) {
      this.cashierName = option.item;
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item;
      this.getCustomerInfoById();
      //this.getOrderByCustomer(this.selectedClassCustomer);
    },
    onSelectedProduct(option) {
      this.searchProduct = '';
      let selectedProduct = option.item;
      let imeiCode = '';
      let productType = selectedProduct.productType;

      if (this.selectTypeSearch === 2) {
        imeiCode = selectedProduct.imeiCode;
        productType = 2;
      }

      let billItem = {
        id: this.countProduct,
        productId: selectedProduct.productId,
        name: selectedProduct.productName,
        code: selectedProduct.productCode,
        price: selectedProduct.sellingPrice,
        type: productType,
        quantity: 1,
        imeiCode: imeiCode,
        totalPrice: selectedProduct.sellingPrice,
        discount: 0,
        quantityInStock: selectedProduct.quantityInStock,
        storeId: this.selectedClassStore,
        comboItems: selectedProduct.listProductInCombo,
        billItemType: 1,
        checked: true,
        listAccessoryBonus: selectedProduct.listAccessoryBonus,
        returnPrice: 0,
        accessoryGroupId: '',
        accessoryGroupCode: '',
        stt: 0,
        note: ''
      };

      if (this.selectTypeSearch === 2) {
        if (this.isExistImeiCode(this.listBillItem, imeiCode)) {
          this.makeToastFaile('Mã IMEI đã tồn tại trong hóa đơn');
        } else {
          this.countProduct++;
          this.proSTT++;
          billItem.id = this.countProduct;
          billItem.stt = this.proSTT;
          this.listBillItem.push(billItem);
          selectedProduct.listAccessoryBonus.forEach((element, index) => {
            let currentId = index + 1;
            let code = `Quà tặng kèm- ${element.accessoryGroupCode}`;
            let accessoryBonus = {
              id: this.countProduct + currentId / 100,
              productId: element.productId,
              name: element.productName,
              code: code,
              price: element.sellingPrice,
              quantity: 1,
              type: element.productType,
              imeiCode: '',
              totalPrice: element.sellingPrice,
              discount: '',
              quantityInStock: element.quantityInStock,
              storeId: this.selectedClassStore,
              billItemType: 2,
              checked: true,
              parentId: billItem.id,
              returnPrice: element.price,
              accessoryGroupId: element.accessoryGroupId,
              accessoryGroupCode: element.accessoryGroupCode,
              listProductVariant: element.listProductVariant
            };
            this.listBillItem.push(accessoryBonus);
          });
        }
      } else {
        if (selectedProduct.quantityInStock > 0) {
          this.countProduct++;
          this.proSTT++;
          billItem.id = this.countProduct;
          billItem.stt = this.proSTT;
          this.listBillItem.push(billItem);
          selectedProduct.listAccessoryBonus.forEach((element, index) => {
            let currentId = index + 1;
            let code = `Quà tặng kèm- ${element.accessoryGroupCode}`;
            let accessoryBonus = {
              id: this.countProduct + currentId / 100,
              productId: element.productId,
              name: element.productName,
              code: code,
              price: element.sellingPrice,
              quantity: 1,
              type: element.productType,
              imeiCode: '',
              totalPrice: element.sellingPrice,
              discount: '',
              quantityInStock: element.quantityInStock,
              storeId: this.selectedClassStore,
              billItemType: 2,
              checked: true,
              parentId: this.countProduct,
              returnPrice: element.price,
              accessoryGroupId: element.accessoryGroupId,
              accessoryGroupCode: element.accessoryGroupCode,
              listProductVariant: element.listProductVariant
            };
            this.listBillItem.push(accessoryBonus);
          });
        } else {
          this.makeToastFaile('Số sản phẩm tồn trong kho không đủ!');
        }
      }
    },
    validateState(name) {
      if (this.isSubmit) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true
      });
    },
    fetchCity() {
      ApiService.setHeader();
      ApiService.get('city/').then(data => {
        this.listCity = data.data.data;
      });
    },
    getDistrict() {
      ApiService.setHeader();
      ApiService.get(`city/${this.selectCity}/district`).then(data => {
        this.listDistrict = data.data.data;
      });
    },
    getWard() {
      // this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectDistrict}/ward`).then(data => {
        this.listWard = data.data.data;
      });
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = false;
      }
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter(item => {
          return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData
        }
      ];
      // this.getProductStock();
    },
    onInputChangeSale(text) {
      if (!text) {
        text = '';
      }
      this.saleName = text;
      const filteredData = this.optionsEmployee[0].data
        .filter(item => {
          return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData
        }
      ];
    },
    onInputChangeTechnical(text) {
      if (!text) {
        text = '';
      }
      this.technicalName = text;

      const filteredData = this.optionsEmployee[0].data
        .filter(item => {
          return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData
        }
      ];
    },
    onInputChangeCash(text) {
      if (!text) {
        text = '';
      }
      this.cashierName = text;
      const filteredData = this.optionsEmployee[0].data
        .filter(item => {
          return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData
        }
      ];
    },
    onInputChangCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomer = text;
      const filteredData = this.optionsCustomer[0].data
        .filter(item => {
          return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptionsCustomer = [
        {
          data: filteredData
        }
      ];
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.filteredOptionsProduct = [
        {
          data: this.optionsProduct[0].data
        }
      ];
      if (this.selectTypeSearch === 1) {
        this.fetchProduct();
      } else if (this.selectTypeSearch === 2) {
        this.fetchProductImei();
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    fetchStore: async function() {
      this.options[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then(response => {
        const stores = response.data.data;
        this.listStore = stores;
        stores.map(element => {
          let item = {
            id: element.id,
            name: element.name
          };
          this.options[0].data.push(item);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    getListOrderSource() {
      ApiService.setHeader();
      ApiService.get(`orderSources/getAll`).then(data => {
        this.listCustomerSource = data.data.data;
      });
    },
    getListEmployee() {
      ApiService.setHeader();
      ApiService.get('employees/getAll').then(data => {
        const employees = data.data.data;
        this.listEmployeeSearch = employees;
        employees.map(element => {
          let employeeName = element.fullName;
          this.optionsEmployee[0].data.push(employeeName);
        });
      });
    },
    getListCustomer() {
      ApiService.setHeader();
      ApiService.get('customer/getAll').then(data => {
        const customers = data.data.data;
        this.listCustomer = customers;
        customers.map(element => {
          let cus = element.phoneNo;
          this.optionsCustomer[0].data.push(cus);
        });
      });
    },
    getCustomerInfoById() {
      ApiService.setHeader();
      ApiService.get(`customer/${this.customerId}`).then(data => {
        let customerInfo = data.data.data;
        this.customerName = customerInfo.fullName;
        this.selectCity = customerInfo.city;
        this.selectWard = customerInfo.ward;
        this.selectDistrict = customerInfo.district;
        this.address = customerInfo.address;
        this.customerPhone = customerInfo.phoneNo;
        this.searchCustomer = customerInfo.phoneNo;
        this.getDistrict();
        this.getWard();
      });
    },
    changePlaceholder() {
      if (this.selectTypeSearch === 1) {
        this.inputSearchProductProp.placeholder =
          'Tìm kiếm theo tên, mã, mã vạch sản phẩm';
        this.fetchProduct();
      } else if (this.selectTypeSearch === 2) {
        this.inputSearchProductProp.placeholder =
          'Tìm kiếm theo mã IMEI sản phẩm';
        this.fetchProductImei();
      }
    },
    convertPrice: function(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchProduct() {
      this.listProduct = [];
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch?storeId=${this.selectedClassStore}&searchProduct=${this.searchProduct}`
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map(element => {
          let suggestName = `(${this.convertPrice(
            element.sellingPrice
          )}) (Tồn CTB: ${element.quantityInStock}) ${element.productCode} - ${
            element.productName
          }`;
          let item = {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            quantityInStock: element.quantityInStock,
            sellingPrice: element.sellingPrice,
            returnSellingPrice: element.returnSellingPrice,
            suggestName: suggestName,
            productType: element.productType,
            listProductInCombo: element.listProductInCombo,
            listAccessoryBonus: element.listAccessoryBonus,
            listAccessoryPromotion: element.listAccessoryPromotion
          };
          this.optionsProduct[0].data.push(item);
        });
      });
    },
    deleteItemInArray(id) {
      let listNew = this.listBillItem.filter(billItem => billItem.id !== id);
      this.listBillItem = [];
      listNew.forEach(element => {
        this.listBillItem.push(element);
      });
      let deleteProductBonus = this.listBillItem.filter(
        billItem => billItem.parentId !== id
      );
      this.listBillItem = [];
      deleteProductBonus.forEach(element => {
        this.listBillItem.push(element);
      });
      this.listBillItem.forEach((element, index) => {
        this.listBillItem[index].id = index + 1;
      });
    },
    updateItem(item) {
      this.listBillItem.forEach((element, index) => {
        if (element.id === item.id) {
          this.listBillItem[index].name = item.name;
          this.listBillItem[index].imeiCode = item.imeiCode;
          this.listBillItem[index].proId = item.proId;
          this.listBillItem[index].price = parseInt(item.price);
          this.listBillItem[index].quantity = parseInt(item.quantity);
          this.listBillItem[index].totalPrice = item.totalPrice;
          this.listBillItem[index].discount = item.discount;
        }
      });
    },
    showConfirmBox: function() {
      Swal.fire({
        title: 'Xác nhận đơn hàng!',
        text:
          'Khách hàng này hiện có đơn hàng đang xử lý, tiếp tục tạo mới đơn hàng ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3699FF',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          this.searchCustomer = '';
          this.customerName = '';
          this.selectCity = '';
          this.selectWard = '';
          this.selectDistrict = '';
          this.address = '';
        }
      });
    },
    createBill() {
      this.isSubmit = true;
      if (this.listBillItem.length <= 0 || this.listBillItem === []) {
        alert('Bạn chưa chọn sản phẩm nào');
        this.isSubmit = false;
        return;
      }
      if (!this.isValidImei) {
        this.makeToastFaile('Mã IMEI không hợp lệ để bán!');
        this.isSubmit = false;
        return;
      }
      if (!this.isValidImeiCode(this.listBillItem)) {
        this.makeToastFaile('Vui lòng nhập IMEI cho sản phẩm IMEI');
        this.isSubmit = false;
        return;
      }
      if (
        !this.validateTransfer() ||
        !this.validateCredit() ||
        !this.validateInstallment() ||
        !this.validateCash() ||
        !this.validateCustomer()
      ) {
        return;
      }
      // let listPro = [];
      // this.listBillItem.forEach((element) => {
      //   let item = {
      //     productId: element.productId,
      //     productPrice: unMaskPrice(element.price),
      //     quantity: element.quantity,
      //     discountAmount: unMaskPrice(element.discount),
      //     imeiCode: element.imeiCode,
      //   };
      //   listPro.push(item);
      // });
      let listPro = this.formatBillItem(this.listBillItem);
      let cusphone = '';
      const customer = this.listCustomer.find(
        s => s.phoneNo === this.searchCustomer
      );
      if (customer) {
        cusphone = customer.phoneNo;
      } else {
        cusphone = this.searchCustomer;
      }
      let data = {
        customerPhone: cusphone,
        customerName: this.customerName,
        customerAddress: this.address,
        customerDistrict: this.selectDistrict,
        customerCity: this.selectCity,
        customerWard: this.selectWard,
        storeId: this.selectedStore,
        saleId: this.selectedEmployeeSale,
        technicalId: this.selectedEmployeeTechnical,
        cashierId: this.selectedEmployeeCashier,
        status: this.selectStatus,
        customerNote: this.noteCustomer,
        saleNote: this.saleNote,
        discountType: this.selectDiscountType,
        discountAmount: unMaskPrice(this.discount),
        transferAmount: this.transferAmount
          ? unMaskPrice(this.transferAmount)
          : 0,
        transferAccountID: this.selectedClassAccountant,
        creditAccountId: this.selectedClassCreditAcc,
        creditAmount: this.creditAmount ? unMaskPrice(this.creditAmount) : 0,
        creditCode: this.creditCode,
        installedMoneyAmount: this.installedMoneyAmount
          ? unMaskPrice(this.installedMoneyAmount)
          : 0,
        installMoneyCode: this.installmentCode,
        installMoneyAccountId: this.selectedInstallmentAccount,
        customerIndentifyNo: this.customerIndentifyNo,
        cashAmount: this.cashAmount ? unMaskPrice(this.cashAmount) : 0,
        cashAccountId: this.selectedCashAccount,
        listBillItem: listPro,
        orderSourceId: this.selectedCustomerSource,
        type: this.billType,
        orderId: this.orderId
      };
      ApiService.setHeader();
      ApiService.post('bills/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            if (this.billType === 1) {
              setTimeout(() => {
                this.$router.push({
                  path: '/bills'
                });
              }, 1000);
            } else {
              setTimeout(() => {
                this.$router.push({
                  path: '/wholesaleInvoices'
                });
              }, 1000);
            }
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    fetchProductImei() {
      this.listProduct = [];
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch/search-by-imei?storeId=${this.selectedClassStore}&imeiCode=${this.searchProduct}`
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map(element => {
          let suggestName = `(${this.convertPrice(element.sellingPrice)}) ${
            element.productCode
          } - ${element.productName} - IMEI: ${element.imeiCode}`;
          let item = {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            sellingPrice: element.sellingPrice,
            suggestName: suggestName,
            productType: element.productType,
            imeiCode: element.imeiCode
          };
          this.optionsProduct[0].data.push(item);
        });
      });
    },
    isExistImeiCode(listProduct, imeiCode) {
      let check = false;
      for (let index = 0; index < listProduct.length; index++) {
        const element = listProduct[index];
        if (element.imeiCode === imeiCode) {
          check = true;
          break;
        }
      }
      return check;
    },
    checkTransferAmount() {
      return unMaskPrice(this.transferAmount) > 0;
    },
    checkCreditPrice() {
      return unMaskPrice(this.creditAmount) > 0;
    },
    checkInstallmentAmount() {
      return unMaskPrice(this.installedMoneyAmount) > 0;
    },
    checkCashAmount() {
      return unMaskPrice(this.cashAmount) > 0;
    },
    fetchAccountant: async function() {
      this.listAccSearch = [];
      ApiService.setHeader();
      if (this.selectedClassStore !== null) {
        let params = {
          storeId: this.selectedClassStore,
          //  tk ngan hang
          type: 2
        };
        ApiService.query('accountants/getByStore', { params }).then(
          response => {
            this.accountantOptions[0].data = [];
            response.data.data.forEach(item => {
              let acc = {
                id: item.id,
                name: item.name,
                code: item.code
              };
              this.listAccSearch.push(acc);
              this.accountantOptions[0].data.push(acc.name);
            });
          }
        );
      } else {
        let params = {
          //  tk ngan hang
          type: 2
        };
        ApiService.query('accountants/getByType', { params }).then(response => {
          this.accountantOptions[0].data = [];
          response.data.data.forEach(item => {
            let acc = {
              id: item.id,
              name: item.name,
              code: item.code
            };
            this.listAccSearch.push(acc);
            this.accountantOptions[0].data.push(acc.name);
          });
        });
      }
    },
    onInputCreditAccChange(text) {
      this.selectedCreditAcc = text;
      const filteredData = this.creditAccOptions[0].data
        .filter(item => {
          return (
            removeAccents(item)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCreditAccOptions = [
        {
          data: filteredData
        }
      ];
    },
    onInputCashAccChange(text) {
      this.selectedCashAcc = text;
      const filteredData = this.cashAccOptions[0].data
        .filter(item => {
          return (
            removeAccents(item)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCashAccOptions = [
        {
          data: filteredData
        }
      ];
    },
    onCashAccSelected(option) {
      this.selectedCashAcc = option.item;
    },
    onCreditAccSelected(option) {
      this.selectedCreditAcc = option.item;
    },
    onInputInstallmentChange(text) {
      this.selectedInstallment = text;
      const filteredData = this.optionsInstallment[0].data
        .filter(item => {
          return (
            removeAccents(item)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsInstallment = [
        {
          data: filteredData
        }
      ];
    },
    onInstallmentSelected(option) {
      this.selectedInstallment = option.item;
    },
    onInputAccChange(text) {
      this.selectedAccountant = text;

      const filteredData = this.accountantOptions[0].data
        .filter(item => {
          return (
            removeAccents(item)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredAccOptions = [
        {
          data: filteredData
        }
      ];
    },
    onAccSelected(option) {
      this.selectedAccountant = option.item;
    },
    fetchCreditAcc: async function() {
      this.listCreditAccSearch = [];
      ApiService.setHeader();
      if (this.selectedClassStore !== null) {
        let params = {
          storeId: this.selectedClassStore,
          //  tk quet the
          type: 3
        };
        ApiService.query('accountants/getByStore', { params }).then(
          response => {
            this.creditAccOptions[0].data = [];
            response.data.data.forEach(item => {
              let acc = {
                id: item.id,
                name: item.name,
                code: item.code
              };
              this.listCreditAccSearch.push(acc);
              this.creditAccOptions[0].data.push(acc.name);
            });
          }
        );
      } else {
        let params = {
          //  tk quet the
          type: 3
        };
        ApiService.query('accountants/getByType', { params }).then(response => {
          this.creditAccOptions[0].data = [];
          response.data.data.forEach(item => {
            let acc = {
              id: item.id,
              name: item.name,
              code: item.code
            };
            this.listCreditAccSearch.push(acc);
            this.creditAccOptions[0].data.push(acc.name);
          });
        });
      }
    },
    fetchCashAcc: async function() {
      this.listCashAccSearch = [];
      ApiService.setHeader();
      if (this.selectedClassStore !== null) {
        let params = {
          storeId: this.selectedClassStore,
          //  tk tien mat
          type: 1
        };
        ApiService.query('accountants/getByStore', { params }).then(
          response => {
            this.cashAccOptions[0].data = [];
            response.data.data.forEach(item => {
              let acc = {
                id: item.id,
                name: item.name,
                code: item.code
              };
              this.listCashAccSearch.push(acc);
              this.cashAccOptions[0].data.push(acc.name);
            });
          }
        );
      } else {
        let params = {
          //  tk tien mat
          type: 1
        };
        ApiService.query('accountants/getByType', { params }).then(response => {
          this.cashAccOptions[0].data = [];
          response.data.data.forEach(item => {
            let acc = {
              id: item.id,
              name: item.name,
              code: item.code
            };
            this.listCashAccSearch.push(acc);
            this.cashAccOptions[0].data.push(acc.name);
          });
        });
      }
    },
    fetchInstallments: async function() {
      let params = {
        page: 1,
        pageSize: 1000
      };
      this.listInstallment = [];
      ApiService.setHeader();
      ApiService.query('/installments', { params }).then(response => {
        this.listInstallment = response.data.data.result;
        this.optionsInstallment[0].data = [];
        this.listInstallment.forEach(element => {
          this.optionsInstallment[0].data.push(element.name);
        });
      });
    },
    isValidImeiCode(listBillItem) {
      let check = true;
      for (let index = 0; index < listBillItem.length; index++) {
        const element = listBillItem[index];
        if (element.type === 2 && element.imeiCode.trim() === '') {
          check = false;
          break;
        }
      }
      return check;
    },
    validateTransfer() {
      let check = true;
      if (
        unMaskPrice(this.transferAmount) > 0 &&
        this.selectedAccountant.trim() === '' &&
        this.isSubmit
      ) {
        check = false;
      }
      return check;
    },
    validateCredit() {
      let check = true;
      if (
        unMaskPrice(this.creditAmount) > 0 &&
        this.selectedCreditAcc.trim() === '' &&
        this.isSubmit
      ) {
        check = false;
      }
      return check;
    },
    validateInstallment() {
      let check = true;
      if (
        unMaskPrice(this.installedMoneyAmount) > 0 &&
        this.selectedInstallment.trim() === '' &&
        this.isSubmit
      ) {
        check = false;
      }
      return check;
    },
    validateCash() {
      let check = true;
      if (
        unMaskPrice(this.cashAmount) > 0 &&
        this.selectedCashAcc.trim() === '' &&
        this.isSubmit
      ) {
        check = false;
      }
      return check;
    },
    validateCustomer() {
      let check = true;
      if (this.searchCustomer.trim() === '' && this.isSubmit) {
        check = false;
      }
      return check;
    },
    formatBillItem(listItem) {
      let listResult = [];
      listItem.sort(this.sortByItemType);
      let listGroup = this.groupBy(listItem, 'billItemType');
      if (listGroup.length === 1) {
        listGroup[0].forEach(element => {
          let item = {
            productId: element.productId,
            productPrice: unMaskPrice(element.price),
            quantity: element.quantity,
            discountAmount: unMaskPrice(element.discount),
            imeiCode: element.imeiCode,
            productType: element.billItemType,
            accessoryGroupId: element.accessoryGroupId,
            accessoryGroupCode: element.accessoryGroupCode,
            repurchasePrice: unMaskPrice(element.returnPrice),
            isGiftTaken: element.checked,
            note: element.note,
            gifts: [],
            attachs: []
          };
          listResult.push(item);
        });
      } else if (listGroup.length === 2) {
        listGroup[0].forEach(element => {
          let listGift = [];
          let listAttach = [];
          listGroup[1].forEach(ele => {
            if (element.id === ele.parentId) {
              let item = {
                productId: ele.productId,
                productPrice: unMaskPrice(ele.price),
                quantity: ele.quantity,
                discountAmount: unMaskPrice(ele.discount),
                imeiCode: ele.imeiCode,
                productType: ele.billItemType,
                accessoryGroupId: ele.accessoryGroupId,
                accessoryGroupCode: ele.accessoryGroupCode,
                repurchasePrice: unMaskPrice(ele.returnPrice),
                isGiftTaken: ele.checked,
                note: ''
              };
              if (ele.billItemType === 2) {
                listGift.push(item);
              } else if (ele.billItemType === 3) {
                listAttach.push(item);
              }
            }
          });
          let billItem = {
            productId: element.productId,
            productPrice: unMaskPrice(element.price),
            quantity: element.quantity,
            discountAmount: unMaskPrice(element.discount),
            imeiCode: element.imeiCode,
            productType: element.billItemType,
            accessoryGroupId: element.accessoryGroupId,
            accessoryGroupCode: element.accessoryGroupCode,
            repurchasePrice: unMaskPrice(element.returnPrice),
            isGiftTaken: element.checked,
            note: element.note,
            gifts: listGift,
            attachs: listAttach
          };
          listResult.push(billItem);
        });
      } else if (listGroup.length === 3) {
        listGroup[0].forEach(element => {
          let listGift = [];
          let listAttach = [];
          listGroup[1].forEach(ele => {
            if (element.id === ele.parentId) {
              let item = {
                productId: ele.productId,
                productPrice: unMaskPrice(ele.price),
                quantity: ele.quantity,
                discountAmount: unMaskPrice(ele.discount),
                imeiCode: ele.imeiCode,
                productType: ele.billItemType,
                accessoryGroupId: ele.accessoryGroupId,
                accessoryGroupCode: ele.accessoryGroupCode,
                repurchasePrice: unMaskPrice(ele.returnPrice),
                isGiftTaken: ele.checked,
                note: ''
              };
              listGift.push(item);
            }
          });
          listGroup[2].forEach(ele => {
            if (element.id === ele.parentId) {
              let item = {
                productId: ele.productId,
                productPrice: unMaskPrice(ele.price),
                quantity: ele.quantity,
                discountAmount: unMaskPrice(ele.discount),
                imeiCode: ele.imeiCode,
                productType: ele.billItemType,
                accessoryGroupId: ele.accessoryGroupId,
                accessoryGroupCode: ele.accessoryGroupCode,
                repurchasePrice: unMaskPrice(ele.returnPrice),
                isGiftTaken: ele.checked,
                note: ''
              };
              listAttach.push(item);
            }
          });
          let billItem = {
            productId: element.productId,
            productPrice: unMaskPrice(element.price),
            quantity: element.quantity,
            discountAmount: unMaskPrice(element.discount),
            imeiCode: element.imeiCode,
            productType: element.billItemType,
            accessoryGroupId: element.accessoryGroupId,
            accessoryGroupCode: element.accessoryGroupCode,
            repurchasePrice: unMaskPrice(element.returnPrice),
            isGiftTaken: element.checked,
            note: element.note,
            gifts: listGift,
            attachs: listAttach
          };
          listResult.push(billItem);
        });
      }
      return listResult;
    },
    clickAll() {
      this.listProductPromotion.forEach((element, index) => {
        this.listProductPromotion[index].checked = !this.checkAllPro;
      });
    },
    clickIndex(id) {
      let check = true;
      for (let index = 0; index < this.listProductPromotion.length; index++) {
        if (this.listProductPromotion[index].id === id) {
          let checked = this.listProductPromotion[index].checked;
          this.listProductPromotion[index].checked = !checked;
        }
      }
      for (let index = 0; index < this.listProductPromotion.length; index++) {
        const element = this.listProductPromotion[index];
        if (element.checked === false) {
          check = false;
          break;
        }
      }
      this.checkAllPro = check;
    },
    showModalAddNote(id) {
      this.productIdUpdateNote = id;
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === id) {
          this.productNote = this.listBillItem[i].name;
          this.noteBillItem = this.listBillItem[i].note;
        }
      }
      this.$refs['add-note-modal'].show();
    },
    hidenModalAddNote() {
      this.$refs['add-note-modal'].hide();
    },
    updateNote() {
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === this.productIdUpdateNote) {
          this.listBillItem[i].note = this.noteBillItem;
        }
      }
      this.$refs['add-note-modal'].hide();
    },
    linkToListImei(id) {
      let item = null;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.id === id) {
          item = element;
          break;
        }
      }
      if (item) {
        const { href } = this.$router.resolve({
          name: 'list-imeis',
          query: {
            searchProduct: item.name,
            storeId: this.selectedClassStore
          }
        });
        window.open(href, '_blank');
      }
    },
    viewInventory(id) {
      let item = null;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.id === id) {
          item = element;
          break;
        }
      }
      if (item) {
        let nameRouter = '';
        if (item.type === 2) {
          nameRouter = 'inventory-detail-imei';
        } else {
          nameRouter = 'inventory-detail';
        }
        const { href } = this.$router.resolve({
          name: nameRouter,
          query: {
            id: item.productId
          }
        });
        window.open(href, '_blank');
      }
    },

    sortByItemType(a, b) {
      if (a.billItemType < b.billItemType) {
        return -1;
      }
      if (a.billItemType > b.billItemType) {
        return 1;
      }
      return 0;
    },
    groupBy(arr, prop) {
      const map = new Map(Array.from(arr, obj => [obj[prop], []]));
      arr.forEach(obj => map.get(obj[prop]).push(obj));
      return Array.from(map.values());
    },
    sortListBillItem(listItem) {
      let listResult = [];
      listItem.sort(this.sortByItemType);
      let listGroup = this.groupBy(listItem, 'billItemType');
      if (listGroup.length === 1) {
        listGroup[0].forEach(element => {
          listResult.push(element);
        });
      } else if (listGroup.length === 2) {
        listGroup[0].forEach(element => {
          listResult.push(element);
          listGroup[1].forEach(ele => {
            if (element.id === ele.parentId) {
              listResult.push(ele);
            }
          });
        });
      } else if (listGroup.length === 3) {
        listGroup[0].forEach(element => {
          listResult.push(element);
          listGroup[1].forEach(ele => {
            if (element.id === ele.parentId) {
              listResult.push(ele);
            }
          });
          listGroup[2].forEach(item => {
            if (element.id === item.parentId) {
              listResult.push(item);
            }
          });
        });
      }
      return listResult;
    },
    showModalAddProductPromotion(id) {
      this.addProPromotionForBillItemId = id;
      this.countProductPromotion = 0;
      this.listProductPromotion = [];
      this.checkAllPro = false;
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === this.addProPromotionForBillItemId) {
          this.modalTitle =
            'Thêm SP bán kèm cho sản phẩm ' + this.listBillItem[i].name;
          let listProductAttach = this.listBillItem[i].listAccessoryPromotion;
          listProductAttach.forEach(element => {
            let code = `SP bán kèm- ${element.accessoryGroupCode}`;
            this.countProduct++;
            let accessoryPromotion = {
              id: this.countProduct,
              productId: element.productId,
              name: element.productName,
              code: code,
              price: element.sellingPrice,
              quantity: 1,
              type: element.productType,
              imeiCode: '',
              totalPrice: element.sellingPrice,
              discount: '',
              quantityInStock: element.quantityInStock,
              storeId: this.selectedClassStore,
              billItemType: 3,
              checked: false,
              parentId: this.addProPromotionForBillItemId,
              returnPrice: element.price,
              accessoryGroupId: element.accessoryGroupId,
              accessoryGroupCode: element.accessoryGroupCode,
              listProductVariant: element.listProductVariant
            };
            this.listProductPromotion.push(accessoryPromotion);
          });
        }
      }
      this.$refs['add-product-promotion-modal'].show();
    },
    hideModalAddProductPromotion() {
      this.$refs['add-product-promotion-modal'].hide();
    },
    addProductPromotionToListBillItem() {
      this.listProductPromotion.forEach(element => {
        if (element.checked) {
          this.countProduct++;
          let accessoryPromotion = {
            id: this.countProduct,
            productId: element.productId,
            name: element.name,
            code: element.code,
            price: element.price,
            quantity: 1,
            type: element.type,
            imeiCode: '',
            totalPrice: element.price,
            discount: '',
            quantityInStock: element.quantityInStock,
            storeId: this.selectedClassStore,
            billItemType: 3,
            checked: true,
            parentId: this.addProPromotionForBillItemId,
            returnPrice: element.returnPrice,
            accessoryGroupId: '',
            accessoryGroupCode: ''
          };
          this.listBillItem.push(accessoryPromotion);
        }
      });
      this.listBillItem = this.sortListBillItem(this.listBillItem);
      this.hideModalAddProductPromotion();
    }
  }
};
</script>

<style>
.alert-header {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.headerTable {
  color: rgb(24, 28, 50);
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}

.form-group label {
  font-weight: 500;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.textPayment {
  font-size: 16px;
  color: brown;
  font-weight: 500;
}

.textTitleFinal {
  font-weight: 500;
}

.textFinal {
  font-weight: 500;
  color: brown;
}
.textTitlePayment {
  font-weight: 500;
  font-size: 14px;
}

.textPayment {
  font-weight: 500;
  color: brown;
}
</style>
